@import "../mixins/flex.scss";
@import "../mixins/fonts.scss";

.claimWrap {
  background-color: var(--clr-secondary);
  .claimContainer {
    padding: 48px 32px;
    .content {
      @include flexCenterBetween();
      flex-direction: column;
      gap: 18px;
      .info {
        text-align: center;
        h3 {
          @include setFontPrimarySemiBold(22px, var(--text-white));
        }
        p {
          @include setFontPrimary(18px, var(--text-faded-dk));
          padding-top: 8px;
        }
      }
      .action {
        @include flexJustifyEnd();
      }
    }
  }
}

@media screen and (min-width: 991px) {
  .claimWrap {
    .claimContainer {
      .content {
        .info {
          width: 80%;
        }
        .action {
          width: 20%;
        }
      }
    }
  }
}
